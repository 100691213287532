<template>
  <!-- 套餐充值 -->
  <package-recharge
    :textLeft="'卡号:' + card"
    :background="tool.getThemeList(theme)"
    :textRight="'账户/卡余额:' + userMoney + '|' + cardMoney"
  >
    <van-tabs
      v-model:active="active"
      :color="tool.getThemeList(theme)"
      title-inactive-color="#AAAAAA"
      :title-active-color="tool.getThemeList(theme)"
      ref="tabs" 
    >
      <!-- 套餐充值 -->
      <van-tab :title="isTotalVoice !== '' ? '话费充值' : '套餐充值'" v-if="theme !== 'AnFang'">
        <div class="content_list">
					<!-- 换卡广告 -->
					<img
						style="width:100%"
						:src="rechargeAdBannerImg?rechargeAdBannerImg:require('_ASSETS_/image/personal/changeCardBanner.png')"
						alt=""
						v-if="isChangeCardInPackage"
						@click="toChangeCard"
					/>
           <!-- 优惠券 -->
           <div v-if="Boolean(couponGoodsList.length)" class="product_box">
                <van-collapse v-model="activeNames">
                    <van-collapse-item name="1" :value="activeNames[0] != '1'?'展开':'收起'">
                        <template #title>
                            <div class="commodity_title">
                                <div class="left">
                                    <img
                                        alt="logo"
                                        src="../../assets/image/packageRecharge/icon_packagerecharge_commodity.png"
                                    />
                                </div>
                                <div class="center">优惠券</div>
                            </div>
                        </template>
                        <!-- 商品列表 -->                        
                        <div
                            class="coupon_goods_list"
                            v-for="(item, index) in couponGoodsList"
                            :key="index"                            
                            :class="{ active: chargeNum == item.price }"
                            @click="pasteHandle(item)"
                        >
                            <div
                                class="list_content"
                                :class="{
                                recommend: Number(item.recommend)
                                }"
                            >                          
                                <div class="left">{{ item.goodsName }}</div>                       
                                <div class="right">                                    
                                    <van-button type="primary" size="small" block>                                                                           
                                        <span style="padding-right: 2px;">
                                            ￥
                                        </span>
                                        <span>
                                            {{item.salePrice}}购
                                        </span>                                        
                                    </van-button>
                                </div>
                            </div>
                        </div>                    
                    </van-collapse-item>                                       
                </van-collapse>
                <!-- 商品列表 收起优惠券列表，只显示前两个 -->                        
                <div v-if="activeNames[0] != '1'">
                  <div
                      class="coupon_goods_list"
                      v-for="(item, index) in couponGoodsList"
                      :key="index"                            
                      :class="{ active: chargeNum == item.price }"
                      @click="pasteHandle(item)">
                      <div
                          class="list_content"
                          :class="{
                          recommend: Number(item.recommend)
                          }"
                          v-if="index < 2"
                      >                          
                          <div class="left">{{ item.goodsName }}</div>                       
                          <div class="right">                                    
                              <van-button type="primary" size="small" block>                                                                           
                                  <span style="padding-right: 2px;">
                                      ￥
                                  </span>
                                  <span>
                                      {{item.salePrice}}购
                                  </span>                                        
                              </van-button>
                          </div>
                      </div>
                  </div> 
                </div>
           </div>          
            <!-- 基础套餐 -->
          <div class="basic_package_one" :class="{isHideTips : !basicPackageTips}">
            <div class="left">
              <img
                alt="logo"
                src="../../assets/image/packageRecharge/icon_packagerecharge_basic.png"
              />
            </div>
            <div class="center">基础套餐</div>
            <div class="right">{{basicPackageTips ? '多次购买累计生效，全国通用，港澳台除外' : '全国通用，港澳台除外'}}</div>
          </div>
          <!-- 套餐列表 -->
          <div v-if="basicDataList.length !== 0">
            <div
              class="basic_package_list"
              v-for="(item, index) in basicDataList"
              :key="index"
              @click="getGuideOrderGoods(item, 'setMeal')"
              :class="{ active: chargeNum == item.price }"
            >
              <div
                class="list_content"
                :class="{
                  recommend: Number(item.recommend)
                }"
              >
                <div class="left_one">
                  <div class="desc_one">{{ item.packageName }}</div>
                  <div class="desc_two_box">
                    <div class="desc_two">
                      <span v-if="!isShowPackageInfoTips">                      
                        {{ item.cycle
                        }}{{
                          item.cycleCategory === 1
                            ? "天"
                            : item.cycleCategory === 2
                            ? "个月"
                            : item.cycleCategory === 3
                            ? "个月"
                            : item.cycleCategory === 4
                            ? "年(12月)"
                            : item.cycleCategory === 5
                            ? "年(360天)"
                            : "天"
                        }}有效期
                      </span>
                      <div class="recommendDesc" v-if="item.recommendDesc">
                        <div class="desc_logo">
                          <img src="../../assets/image/personal/click.png" />
                        </div>
                        <div class="desc_two">
                          {{ item.recommendDesc }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="desc_three">{{ item.remarks }}</div>
                </div>
                <div class="right">
                  <div v-if="item.couponDistributionRecordId">
                    <div class="desc_two" >券后价:￥{{item.postCouponPrice}}</div>
                    <div class="desc_three desc_right">原价:{{item.price}}</div>
                  </div>
                  <div v-else>
                    <div class="desc_one_" v-if="showPrice(item)">
                      优惠{{ (item.markPrice - item.price).toFixed(2) }}¥
                    </div>
                    <div class="desc_two">价格:{{ item.price }}¥</div>
                    <div class="desc_three" v-if="showPrice(item)">
                      原价:{{ item.markPrice }}元
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="empty_list">暂无基础套餐~~~</div>

          <!-- 叠加套餐 -->

          <div class="basic_package_two">
            <div class="left">
              <img
                alt="logo"
                src="../../assets/image/packageRecharge/icon_packagerecharge_overlay.png"
              />
            </div>
            <div class="center">叠加套餐</div>
            <div class="right">跟随主套餐生效</div>
          </div>
          <template v-if="Boolean(alertMsg)">
            <!-- 叠加包购买次数提示 -->
            <div class="empty_list">{{alertMsg}}</div>
          </template>
          <template v-else>
            <!-- 套餐列表 -->
            <div v-if="speedDataList.length !== 0">
              <div
                class="basic_package_list"
                v-for="(item, index) in speedDataList"
                :key="index"
                @click="paste(item, 'superposition')"
                :class="{ active: chargeNum == item.price }"
              >
                <div
                  class="list_content"
                  :class="{
                    recommend: Number(item.recommend)
                  }"
                >
                  <div class="left_one">
                    <div class="desc_one">{{ item.packageName }}</div>
                    <div class="desc_two_box">
                      <div class="desc_two">
                        有效期跟随生效基础套餐
                        <div class="recommendDesc" v-if="item.recommendDesc">
                          <div class="desc_logo">
                            <img src="../../assets/image/personal/click.png" />
                          </div>
                          <div class="desc_two">
                            {{ item.recommendDesc }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="desc_three">{{ item.remarks }}</div>
                  </div>
                  <div class="right">
                    <div v-if="item.couponDistributionRecordId">
                      <div class="desc_two" >券后价:￥{{item.postCouponPrice}}</div>
                      <div class="desc_three desc_right">原价:{{item.price}}</div>
                    </div>
                    <div v-else>
                      <div class="desc_one_" v-if="showPrice(item)">
                        优惠{{ (item.markPrice - item.price).toFixed(2) }}¥
                      </div>
                      <div class="desc_two">价格:{{ item.price }}¥</div>
                      <div class="desc_three" v-if="showPrice(item)">
                        原价:{{ item.markPrice }}元
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="empty_list">暂无叠加套餐~~~</div>
          </template>
        </div>
      </van-tab>
      <!-- 余额充值 -->
      <van-tab title="余额充值" v-if="isCash !== '0' || info.labels.includes(2) || info.labels.includes(3)">
        <!--套餐列表-->
        <div class="content_list">
          <ul class="package_box">
            <li
              class="package_box_"
              v-for="(item, index) in moneyConfig"
              :key="index"
              @click="paste(item, 'balance')"
              :class="{ active: chargeNum == item }"
            >
              <div class="sale_price">¥{{ item }}</div>
              <div class="show_price" v-if="theme !== 'AnFang'">售价:￥{{ item }}</div>
            </li>
          </ul>
          <div v-if="moneyConfig.length == 0" class="empty_list">
            暂无余额套餐包~~~
          </div>
          <div class="prompt">充值完余额后需要订购套餐使用</div>
        </div>
      </van-tab>
      <!-- 短信充值 -->
      <van-tab title="短信充值" v-if="theme !== 'AnFang'">
        <div class="content_list">
          <!-- 短信套餐 -->
          <div class="Sms_package">
            <div class="left">
              <img
                alt="logo"
                src="../../assets/image/packageRecharge/icon_packagerecharge_basic.png"
              />
            </div>
            <div class="center">短信包</div>
            <div class="right">全国短信(不含港澳台)，订购即生效</div>
          </div>
          <!-- 套餐列表 -->
          <div v-if="SMSPackage.length !== 0">
            <div
              class="basic_package_list"
              v-for="(item, index) in SMSPackage"
              :key="index"
              @click="getGuideOrderGoods(item, 'setMeal')"
              :class="{ active: chargeNum == item.price }"
            >
              <div
                class="list_content"
                :class="{
                  recommend: Number(item.recommend)
                }"
              >
                <div class="left_one">
                  <div class="desc_one">{{ item.packageName }}</div>
                  <div class="desc_two_box">
                    <div class="desc_two">
                      {{ item.cycle }}有效期
                      <div class="recommendDesc" v-if="item.recommendDesc">
                        <div class="desc_logo">
                          <img src="../../assets/image/personal/click.png" />
                        </div>
                        <div class="desc_two">
                          {{ item.recommendDesc }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="desc_three">{{ item.remarks }}</div>
                </div>
                <div class="right">
                  <div v-if="item.couponDistributionRecordId">
                    <div class="desc_two" >券后价:￥{{item.postCouponPrice}}</div>
                    <div class="desc_three desc_right">原价:{{item.price}}</div>
                  </div>
                  <div v-else>
                    <div class="desc_one_" v-if="showPrice(item)">
                      优惠{{ (item.markPrice - item.price).toFixed(2) }}¥
                    </div>
                    <div class="desc_two">价格:{{ item.price }}¥</div>
                    <div class="desc_three" v-if="showPrice(item)">
                      原价:{{ item.markPrice }}元
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="empty_list">本卡暂无该套餐包~~~</div>
        </div>
      </van-tab>
      <!-- 充值弹窗 -->
      <popup-container
        ref="actionNotice"
        @selectActive="selectActive"
        :title="title"
				:buyMultiplePackages="buyMultiplePackages"
        :chargeNum="Number(chargeNum)"
        :setMealObject="setMealObject"
        :userMoney="Number(userMoney)"
        :cardMoney="Number(cardMoney)"
      ></popup-container>
      <!-- 优惠券返佣版本 -->
      <!-- 优惠券面板 -->      
      <coupon-container
        ref="couponNotice"    
        :couponObject="couponObject"
        @onChang="queryPackages()"    
      ></coupon-container>
    </van-tabs>
  </package-recharge>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance, nextTick } from "vue";
import { queryPackages, advertLog , diagnosis, testCard } from "_API_/api.services";
import { getStore, removeStore } from "@/utils/store";
import { useRoute } from "vue-router";
import { isDevice } from "@/utils/utils";
import { Toast } from "vant";
export default {
  setup() {
    const state = reactive({
      info: {labels:[]},
      theme: "", //主题名称
      active: 0, //套餐名称下标
      typeActive: 0, //套餐没余额  跳转去余额充值的下标
      card: "", //卡号
      userMoney: "", //账户余额
      cardMoney: "", //卡余额
      title: "", //弹窗顶部标题
      chargeNum: "", // 选中充值包的金额
      setMealObject: {}, //套餐对象
      couponObject: {}, // 优惠券对象
      moneyConfig: [], // 余额充值包数组
      alertMsg: '', // 叠加包购买次数提示
      basicDataList: [], //基础套餐列表
      speedDataList: [], //叠加套餐列表
      SMSPackage: [], //短信充值列表
      preRechargeDay: "", //叠加包提前停机天数
			buyMultiplePackages: 0, // 多套餐购买 0-关闭 1-开启
      isTotalVoice: "",
      isCash: getStore({ name: "isCash" }),
			isChangeCardInPackage: false, // 获取卡片是否为提示换卡 且 待申请
			basicPackageTips: true,  // 基础套餐关闭 【多次购买累计生效】 当前仅安防平台
      isShowPackageInfoTips: false, // 语音平台屏蔽 【有效期】
      rechargeAdBannerImg:"",// 充值换卡广告图
      activeNames: ['1'],
      couponGoodsList: [],
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $http: http, $router : router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    state.active = Number(route.query.active) || 0;
    const methods = {
      async diagnosis(){
        await diagnosis(state.card,isDevice()).then(async ({ data: res }) => {
          if(!res.data) return
          // if(res.data?.operatorsOption){
          //   res.data.operatorsOption = res.data.operatorsOption.split(',')
          // }
          // if(res.data?.newOperators){
          //   state.form.newOperators = res.data.newOperators
          // }
          // state.diagnosisData = res.data
          
          // 获取换卡类型 及 换卡申请状态
          state.isChangeCardInPackage = getStore({ name: `isChangeCardInPackage`}) || false
          if(res.data.bannerMap[res.data['rechargeAdBannerId']]){
            state.rechargeAdBannerImg = res.data.bannerMap[res.data['rechargeAdBannerId']]
          }
        });
      },
			// 提示换卡
			toChangeCard(){
				store.dispatch("SetUserInfo", {isPackage: false})
        advertLog({
          param: state.info.iccid,
          type:16,
          browserType: state.info.device
        })
				router.push({
					name:"personal-changecard"
				})
			},
      //切换active
      selectActive(val) {
        state.active = val.active; //套餐名称下标
        state.typeActive = val.typeActive; //套餐没余额  跳转去余额充值的下标
      },
      //价格选择性展示
      showPrice(item) {
        if (item.markPrice > 0 && item.markPrice > item.price) {
          return true;
        } else {
          return false;
        }
      },      
      // 选中充值包的方法
      async paste(item, type) {
        switch (type) {
          case "setMeal":
            state.title = "套餐详情";
            state.chargeNum = Number(item.price);
            state.setMealObject = item;
            ctx.$refs.actionNotice.actionType = 1;
            nextTick(() => {
              ctx.$refs.actionNotice.payInfo.money = Number(item.price) || 0;
              ctx.$refs.actionNotice.isShow();
              ctx.$refs.actionNotice.payInfo.orderType = "ordinaryOrder";
              ctx.$refs.actionNotice.payInfo.activeType = state.active;
            });
            break;
          case "superposition":
            state.title = "套餐详情";
            state.chargeNum = Number(item.price);
            state.setMealObject = item;
            ctx.$refs.actionNotice.actionType = 1;
            nextTick(() => {
              if (
                (new Date().getTime() >
                new Date(item.cardPackageEndTime).getTime() -
                  state.preRechargeDay * 24 * 60 * 60 * 1000) && item.cycleCategory > 3
              ) {
                return tool.confirm(
                  {
                    title: "提示",
                    msg:
                      "离年度结算已经很近，购买叠加包后将会随年度结算一起失效，是否购买？"
                  },
                  () => {
                    ctx.$refs.actionNotice.payInfo.money =
                      Number(item.price) || 0;
                    ctx.$refs.actionNotice.isShow();
                    ctx.$refs.actionNotice.payInfo.orderType = "ordinaryOrder";
                    ctx.$refs.actionNotice.payInfo.activeType = state.active;
                  },
                  () => {}
                );
              }
              ctx.getSuperposition(item);
            });
            break;
          case "balance":
            state.title = "充值金额";
            state.chargeNum = Number(item);
            ctx.$refs.actionNotice.actionType = 2;
            nextTick(() => {
              ctx.$refs.actionNotice.payInfo.money = Number(item) || 0;
              ctx.$refs.actionNotice.isShow();
              ctx.$refs.actionNotice.payInfo.activeType = state.typeActive;
            });
            break;
          default:
            break;
        }
      },
      // 判断基础套餐是否需要购买优惠券才可使用
      async getGuideOrderGoods(item,type){
        http.post("/card/getGuideOrderGoods", { iccid: state.info.iccid,packageId:item.packageId }).then(({ data: res }) => {
          if (res.code == 0) {
              if(res.data){
                state.couponObject = res.data;
                let fullUseArr = state.couponObject.couponList.filter((element)=>{

                  return element.fullUse <= item.price
                })
                let sortMax = fullUseArr.sort((a, b) => { return b.couponAmount-a.couponAmount })[0].couponAmount  
                  Toast({
                    type: "text",
                    message: `先购优惠券，立省${sortMax}元`,
                    icon: "",
                    mask: false,
                    duration:"3000",
                    position:'bottom',
                    zIndex:99999999999
                  });
                nextTick(() => {
                    ctx.$refs.couponNotice.isShow();
                });
                return
              }
              ctx.paste(item, type)
            } else {
               tool.toast(
                {
                  msg: res.msg,
                  duration: 3000
                },
                () => {}
              );
              return
            }
          });
      },
      //获取叠加包提前停机天数
      getPreRechargeDay() {
        http
          .post("/card/getPreRechargeDay", { card: state.card })
          .then(({ data: res }) => {
            if (res.code == 0) {
              state.preRechargeDay = res.preRechargeDay;
            } else {
              tool.toast(
                {
                  msg: res.msg,
                  duration: 1500
                },
                () => {}
              );
            }
          });
      },
      //叠加包弹窗判断
      getSuperposition(item) {
        ctx.$refs.actionNotice.payInfo.money = Number(item.price) || 0;
        ctx.$refs.actionNotice.isShow();
        ctx.$refs.actionNotice.payInfo.orderType = "ordinaryOrder";
        ctx.$refs.actionNotice.payInfo.activeType = state.active;
      },
      // 获取优惠券数据
      getAvailableCouponGoodsList() {
          http
            .post("/card/getAvailableCouponGoodsList", { iccid: state.info.iccid })
            .then(({ data: res }) => {
              if (res.code == 0) {                  
                  state.couponGoodsList = res.data
              } else {
                tool.toast(
                  {
                    msg: res.msg,
                    duration: 1500
                  },
                  () => {}
                );
              }
            });
      },
      // 选中优惠券
      pasteHandle(item){
        state.couponObject = item;
        nextTick(() => {
            ctx.$refs.couponNotice.isShow();
        });
      },
      queryPackages(){
        // 查询套餐信息
        queryPackages({ card: state.info.iccid }).then(({ data: res }) => {
          if (res.code == 0) {
            let _config = res.list.amountConfig || [];
            state.alertMsg = res.list.alertMsg || ''
            state.moneyConfig = _config.split("|");
            state.basicDataList = res.list.basicPackage || "";
            state.speedDataList = res.list.speedPackage || "";
            state.SMSPackage = res.list.SMSPackage || "";
            state.cardMoney = res.list.money || 0; //卡余额
            state.userMoney =res.list.userMoney || 0; //账户余额
            state.buyMultiplePackages = Number(res.list.buyMultiplePackages) // 多套餐购买 0-关闭 1-开启
            return;
          }
          tool.alert(
            {
              title: "提示",
              msg: res.msg
            },
            () => {}
          );
        });
      },
        // 检查卡
        checkCard (conf) {    
            return new Promise((resolve) => {                
                testCard({ card: conf.iccid, device: isDevice() }).then(({ data: res}) => {
                    if (res.msg == '该卡不存在') {
                        tool.alert(
                            {
                                title: '提示',
                                msg: res.msg
                            },
                            () => {
                                store.dispatch("UserLogout");
                                removeStore({ name: "tongLianInfo"});
                                removeStore({ name: "contractStatus", type: "session" });                                
                                removeStore({ name: "userOpenId", type: "session" });
                                removeStore({ name: "userBind", type: "session" });
                                removeStore({ name: "initAd", type: "session" });
                                removeStore({ name: "isShowGetCardAd", type: "session" });
                                location.reload();
                            }
                        )
                        return resolve(false) 
                    }
                    resolve(true)
                }) 
            })   
        }, 
    };
    onMounted(async () => {
      //获取主题名称
      state.theme = store.state.common.theme;
			// 基础套餐关闭 【多次购买累计生效】 当前仅安防平台
			state.basicPackageTips = window.SITE_CONFIG['basicPackageTips'] || false
      // 语音平台屏蔽 【有效期】
			state.isShowPackageInfoTips = window.SITE_CONFIG['isShowPackageInfoTips'] || false
      //获取用户信息
      const info = store.state.user.userInfo;
      state.info = info
      const isContinueRequest = await ctx.checkCard(info);        
      if(!isContinueRequest) return;
      //获取卡号信息
      state.card = info.virtualId || info.iccid;
      state.isTotalVoice = info.totalVoice; //是否语音卡
      ctx.queryPackages()
      ctx.getPreRechargeDay();
      ctx.getAvailableCouponGoodsList();
      methods.diagnosis()
      if(info.labels.includes(2) || state.theme == 'AnFang'){
        nextTick(() => {
          state.active = 0
          ctx.$refs.tabs.resize();
        });
      }
    });
    return {
      ...toRefs(state),
      ...methods,
      tool
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.content_list {
  height: calc(100vh - 190px);
  overflow-y: auto;
  .basic_package_one,
  .basic_package_two,
  .Sms_package {
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    margin: 40px 0px 0px 10px;
    .left img {
      width: 48px;
      height: 48px;
    }
    .center {
      font: {
        size: 30px;
        weight: 400;
      }
      color: #333333;
    }
    .right {
      line-height: 48px;
      font: {
        size: 22px;
        weight: 400;
      }
      color: #aaaaaa;
    }
  }
  .basic_package_one {
    width: 700px;
  }
	.isHideTips{
		width: 480px;
	}
  .basic_package_two {
    width: 420px;
  }
  .Sms_package {
    width: 580px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    margin: 40px 0px 0px 10px;
  }
  .basic_package_list {
    width: 690px;
    height: auto;
    background: #ffffff;
    border-radius: 20px;
    margin: 20px auto;
    position: relative;
    overflow: hidden;
    .list_content {
      height: auto;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      .left_one,
      .left_two,
      .right {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        margin-left: 80px;
        .desc_one {
            font-weight: 400;
            font-size: 30px;
          @include font_color("font_color1");
        }
        .desc_two,
        .desc_three {
          color: #777777;
        }
        .desc_right {
          text-align: right;
        }
        .desc_two {
          font-size: 26px;
          display: flex;
        }
        .desc_three {
          font-size: 22px;
          overflow-x: auto;
          white-space: nowrap;
        }
      }
      .left_one {
        justify-content: space-around;
        .desc_two_box {
          .recommendDesc {
            display: flex;
            margin-left: 8px;
            .desc_two {
              font: {
                size: 26px;
                weight: 500;
              }
              color: #777777;
            }
            .desc_logo img {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
      .left_two {
        justify-content: space-evenly;
      }
      .right {
        max-width: 250px;
        align-items: flex-end;
        justify-content: space-around;
        align-items: center;
        margin: 0px 20px 0px 0px;
        .desc_one_ {
          padding: 2px 15px;
          border-radius: 15px;
          font: {
            size: 22px;
            weight: 400;
          }
          color: #ffffff;
          background: #f14153;
          border-radius: 21px;
          text-align: center;
          line-height: 41px;
          font-size: 24px;
        }
        .desc_two {
          font: {
            size: 30px;
            weight: 500;
          }
          color: #f14153;
        }
        .desc_three {
          font: {
            size: 26px;
            weight: 400;
          }
          color: #aaaaaa;
          text-decoration: line-through;
        }
      }
    }
    .list_content.recommend:after {
      position: absolute;
      top: -0.333vw;
      left: -5vw;
      width: 14.667vw;
      height: 6vw;
      line-height: 8vw;
      text-align: center;
      font-size: 3.467vw;
      color: #fff;
      background: red;
      display: block;
      transform: rotateZ(-42deg) scale(0.8);
      content: "推荐";
    }
  }
  .empty_list {
    width: 590px;
    height: auto;
    background: #ffffff;
    border-radius: 20px;
    margin: 20px auto;
    font-size: 26px;
    color: #b2b2b2;
    padding: 40px;
    text-align: center;
  }
  .package_box {
    width: 710px;
    display: flex;
    text-align: left;
    margin: 20px auto 0px;
    flex-wrap: wrap;
    .package_box_ {
      width: 210px;
      height: 142px;
      background: #ffffff;
      border-radius: 20px;
      @include border_color("border_color1");
      border: 2px solid #2196f3;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      margin: 10px 10px 0px;
      .sale_price {
        font: {
          size: 36px;
          weight: 500;
        }
        color: #333333;
      }
      .show_price {
        font: {
          size: 26px;
          weight: 400;
        }
        color: #aaaaaa;
      }
    }
    .active {
      background: rgba(33, 150, 243, 0.1);
      border-radius: 20px;
      @include border_color("border_color1");
      border: 2px solid #2196f3;
    }
  }
}

.product_box{
    /deep/ .van-cell{
            // margin: 40px 0px 0px 10px;
            padding: 40px 34px 0 26px;
            background: #f1f2f3;
    }
    /deep/ .van-collapse-item{
        top: 12px;
    }
    /deep/ .van-collapse-item__content{
        background: #f1f2f3;
        padding: 0;
    }
    /deep/ .van-cell__value{
        color: #aaaaaaff;
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
    }
    .commodity_title{
        width: 152px;
        display: flex;
        justify-content: space-between;
        .left img {
          width: 48px;
          height: 48px;
        }
        .center{
            font: {
                size: 30px;
                weight: 400;
            }
            color: #333333;
        }
    }
    .coupon_goods_list{
        width: 690px;        
        border-radius: 12px;
        margin: 20px auto;
        opacity: 1;
        background: #ffffffff;
        .list_content {
            min-height: 104px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            .left{
                padding-left: 20px;
                font: {
                    size: 30px;
                    weight: 400;
                }
                font-family: "PingFang SC";
                color: #333333;
            }
            .right{
                padding-right: 20px;
                .van-button--primary {                  
                    min-width: 150px;
                    min-height: 58px;
                    border-radius: 16px;
                    border: none;
                    opacity: 1;
                    background: linear-gradient(139deg, #ff974bff 0%, #fe3e14ff 100%);
                }
                span{
                    font: {
                        size: 30px;
                        weight: 400;
                    }
                    font-family: "PingFang SC";
                    color: #fff;
                }
            }
        }    
    }
}
.prompt{
    color: #FF9966;
    font-size: 32px;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 60px;
    text-align: center;
  }

</style>
